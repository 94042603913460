import { graphql, Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"
import SEO from "../components/seo"
import "./podcastpost.sass"

class PodcastPostTemplate extends Component {
  render() {
    const { data, pageContext } = this.props
    const pageURL = `https://collegeoptimized.com/${pageContext.filename}`
    return (
      <>
        <SEO
          title={`${data.file.childMarkdownRemark.frontmatter.title} | Podcast`}
          description={data.file.childMarkdownRemark.frontmatter.description}
          meta={[
            {
              name: "og:url",
              content: pageURL,
            },
            {
              name: "og:image",
              content: `https://collegeoptimized.com${data.file.childMarkdownRemark.frontmatter.thumbnail.seoFacebook.fixed.src}`,
            },
            {
              name: "twitter:image",
              content: `https://collegeoptimized.com${data.file.childMarkdownRemark.frontmatter.thumbnail.seoTwitter.fixed.src}`,
            },
          ]}
        />
        <div className="spacer-3" />
        <div className="container">
          <p>
            <AniLink
              swipe
              duration={0.3}
              entryOffset={100}
              direction="right"
              to="/podcast"
            >
              <FaAngleLeft /> Podcast
            </AniLink>
          </p>
          <h1>{data.file.childMarkdownRemark.frontmatter.title}</h1>
          <p className="podcast-info">
            {data.file.childMarkdownRemark.frontmatter.description}
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: data.file.childMarkdownRemark.html,
            }}
          />
          <div className="podcast-prevnext">
            <div className="prev text-left">
              <p>
                {pageContext.previousNode && (
                  <Link to={`/podcast/${pageContext.previousNode.name}`}>
                    <FaAngleLeft />
                    {
                      pageContext.previousNode.childMarkdownRemark.frontmatter
                        .title
                    }
                  </Link>
                )}
              </p>
            </div>
            <div className="next text-right">
              <p>
                {pageContext.nextNode && (
                  <Link to={`/podcast/${pageContext.nextNode.name}`}>
                    {pageContext.nextNode.childMarkdownRemark.frontmatter.title}
                    <FaAngleRight />
                  </Link>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="spacer-3" />

        <div className="podcast-share container">
          <FacebookShareButton url={pageURL}>
            <FacebookIcon size={48} iconBgStyle={{ fill: "none" }} />
          </FacebookShareButton>

          <TwitterShareButton url={pageURL}>
            <TwitterIcon size={48} iconBgStyle={{ fill: "none" }} />
          </TwitterShareButton>

          <LinkedinShareButton url={pageURL}>
            <LinkedinIcon size={48} iconBgStyle={{ fill: "none" }} />
          </LinkedinShareButton>

          <TumblrShareButton url={pageURL}>
            <TumblrIcon size={48} iconBgStyle={{ fill: "none" }} />
          </TumblrShareButton>

          <PinterestShareButton
            url={pageURL}
            media={`https://collegeoptimized.com${data.file.childMarkdownRemark.frontmatter.thumbnail.body.fluid.src}`}
          >
            <PinterestIcon size={48} iconBgStyle={{ fill: "none" }} />
          </PinterestShareButton>

          <EmailShareButton url={pageURL}>
            <EmailIcon size={48} iconBgStyle={{ fill: "none" }} />
          </EmailShareButton>
        </div>
      </>
    )
  }
}

PodcastPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const postQuery = graphql`
  query PodcastByFileName($filename: String!) {
    file(name: { eq: $filename }) {
      childMarkdownRemark {
        frontmatter {
          description
          thumbnail {
            body: childImageSharp {
              fluid(quality: 100, maxWidth: 540) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
            seoFacebook: childImageSharp {
              fixed(quality: 100, width: 1200, height: 630) {
                src
              }
            }
            seoTwitter: childImageSharp {
              fixed(quality: 100, width: 280, height: 150) {
                src
              }
            }
          }
          title
        }
        html
      }
    }
  }
`

export default PodcastPostTemplate
